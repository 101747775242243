import { tns } from '../node_modules/tiny-slider/src/tiny-slider.module';

const slider = tns({
    container: '.impressions .carousel',
    items: 1.25,
    autoplay: true,
    autoplayButtonOutput: false,
    controls: false,
    lazyload: true,
    loop: true,
    mouseDrag: true,
    navPosition: 'bottom',
    responsive: {
        576: {
            items: 1.5
        },
        992: {
            items: 2.5
        }
    }
});